export const etAdminMenu = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/main/dashboard',
    icon: 'dashboard',
    children: [],
  },
  {
    key: 'categories',
    title: 'Categories',
    route: '/main/categories',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'metal-price',
    title: 'Metal Price',
    route: '/main/metal-price',
    icon: 'dollar-circle',
    children: [],
  },
  {
    key: 'items',
    title: 'Item',
    route: '/main/item',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'dynamic-section',
    title: 'Dynamic Section',
    route: '/main/dynamic-section',
    icon: 'question-circle',
    children: [

    ],
  },
  {
    key: 'collection',
    title: 'Collection',
    route: '/main/collection',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'banner',
    title: 'Banner',
    route: '/main/banner',
    icon: 'question-circle',
    children: [],
  },
  // {
  //   key: 'discounts',
  //   title: 'Discount',
  //   route: '/main/discounts',
  //   icon: 'percentage',
  //   children: [],
  // },
  {
    key: 'customer',
    title: 'Customer',
    route: '/main/user',
    icon: 'user',
    children: [],
  },
  {
    key: 'orders',
    title: 'Orders',
    route: '/main/order',
    icon: 'ordered-list',
    children: [],
  },
  /* {
    key: 'cart',
    title: 'Show Interest',
    route: '/main/cart',
    icon: 'shopping-cart',
    children: [],
  }, */

  {
    key: 'general-info',
    title: 'General Info',
    route: '/main/general-info',
    icon: 'info-circle',
    children: [],
  },
  {
    key: 'contact-info',
    title: 'Contact Info',
    // route: '/main/contact-info/contact',
    icon: 'contacts',
    children: [
      {
        key: 'contact',
        title: 'Contact',
        route: '/main/contact-info/contact',
        icon: 'info-circle',
        children: [],
      },
      {
        key: 'bespok-contact',
        title: 'Bespok Contact',
        route: '/main/contact-info/bespoke-contact',
        icon: 'info-circle',
        children: [],
      },
    ],
  },

  {
    key: 'gallery',
    title: 'Gallery',
    route: '/main/gallery',
    icon: 'file-image',
    children: [],
  },

  // {
  //   key: 'newsletter',
  //   title: 'Newsletter',
  //   route: '/main/newsletter',
  //   icon: 'user',
  //   children: [],
  // }
];
